/* Placer le Footer en retrait */
main {
  font-family: "Poppins", serif;
}

/* Placer le Footer en retrait */
main {
  min-height: 70vh;
}

/* Désactiver la barre horizontale (test) */
body {
  overflow-x: hidden !important;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/* style des sections (bleue) */
.blue-section {
  background-color: #32cd32;
}

/* Couleur du background */
body {
  background-color: white !important;
}

/* Assombrissement des bannières */
.img-darker {
  opacity: 0.5;
}

.darker-style {
  background-color: black;
}

/* Centrer les éléments */
.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Centrer le formulaire de connexion */
.login-center {
  margin-top: 250px;
}

/* Centrer le formulaire d'enregistrement */
.sign-in-center {
  margin-top: 70px;
}

/* Portail ADMIN : Centrer le formulaire de connexion */
.admin-center {
  margin-top: 130px;
}

/* Titre de section (ONLY BLUE SECTION) */
.title-section {
  color: white;
}

/* Titre 1 bannière */
.title-banner {
  color: white;
  margin-bottom: 170px;
  font-size: 70px;
}

/* Footer background color  */

.footer-color {
  background-color: #212529 !important ;
}

/* Footer Copyright text style */
.copyright-style {
  color: white;
}

/* Footer padding */
.footer-padding {
  padding-top: 50px;
}

/* Éléments en gras de l'accordéon */
.accordion-blue {
  color: #337ff1;
}

/* Section padding (vers le bas) */
.section-padding {
  padding-bottom: 30px;
}

/* STYLING LIÉ AUX QUESTIONNAIRES */
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.App .card {
  max-width: 400px;
  width: 100%;
  text-align: center;
}

label.radio {
  display: block;
  text-align: left;
  padding: 15px;
  margin-bottom: 5px;
  font-size: 20px;
}

label.radio + .radio {
  margin-left: 0;
}

label.radio input {
  margin-right: 10px;
}

/* Image - Page d'inscription / réduction d'espace */
.bottom-padding {
  margin-bottom: -100px;
}

/* Eye toggle password */
.eye-toggle {
  right: 50px;
  cursor: pointer;
}

/* Couleur questions - enquête */
.question-title {
  color: #ff8c00;
}
